<template>
  <div>
    <v-card elevation="2">
      <v-container py-5>
        <v-row class="d-flex justify-center align-center">
          <v-col lg="3">
            <validation-provider v-slot="{ errors }" vid="areas">
              <autocomplete-areas v-model="area" :error-messages="errors" />
            </validation-provider>
          </v-col>
          <v-col lg="5" class="datas-container">
            <date-picker :model-config="modelConfig" v-model="range" is-range>
              <template v-slot="{ inputValue, inputEvents }">
                <div class="datas-input d-flex justify-center align-center">
                  <input
                    :value="inputValue.start"
                    v-on="inputEvents.start"
                    class="text-center calendario-input mx-3"
                    :class="{
                      'input-white': $vuetify.theme.dark,
                    }"
                  />
                  <v-icon class="icone"> mdi-arrow-right </v-icon>
                  <input
                    :value="inputValue.end"
                    v-on="inputEvents.end"
                    class="text-center calendario-input mx-3"
                    :class="{
                      'input-white': $vuetify.theme.dark,
                    }"
                  />
                </div>
              </template>
            </date-picker>
          </v-col>
          <v-col lg="2">
            <btn-buscar
              class="text-center"
              :carregando="$store.state.layout.loading"
              :temPermissao="true"
              @click="carregarRelatorios()"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-card
      class="mx-5 my-4 flex-column"
      v-for="item in relatorioArea"
      :key="item.id"
    >
      <v-col class="ml-5"
        >Área: <span class="titulo-area">{{ item.nome }}</span></v-col
      >
      <v-col>
        <v-data-table
          disable-filtering
          disable-sort
          :headers="cabecalho"
          :items="item.linhas"
          hide-default-footer
          disable-pagination
        >
        </v-data-table>
      </v-col>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";
import relatorios from "@/api/relatorios";
import formatarData from "@/utils/formatar-data-input";
export default {
  data() {
    return {
      relatorioArea: [],
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD", // Uses 'iso' if missing
      },
      area: null,
      totalRegistros: 0,
      cabecalho: [
        { text: "Usuário", align: "center", value: "usuario", divider: true },
        { text: "Data", align: "center", value: "data", divider: true },
        {
          text: "Quantidade.Pagamentos",
          align: "center",
          value: "quantidadeAtendimentos",
          divider: true,
        },
        { text: "Dinheiro", align: "end", value: "dinheiro", divider: true },
        { text: "Débito", align: "end", value: "debito", divider: true },
        { text: "Crédito", align: "end", value: "credito", divider: true },
        { text: "Pix", align: "end", value: "pix", divider: true },
        { text: "Total", align: "end", value: "soma" },
      ],
      range: {
        start: moment().subtract(1, "months").format("YYYY-MM-DD"),
        end: moment().format("YYYY-MM-DD"),
      },
    };
  },

  methods: {
    async carregarRelatorios() {
      try {
        this.$store.commit(`layout/SET_LOADING`, true);

        const datas = {
          data_inicial: this.range.start,
          data_final: this.range.end,
          area_id: this.area,
        };
        const dados = datas;
        const resposta = await relatorios.relatorioFechamentoOperador(dados);

        var areas = resposta.data.data;

        const relatoriosData = [];
        areas.map((area) => {
          const total = area.fechamentos_operadores;
          const totalFiltrado = {
            nome: area.nome,
            linhas: [],
            id: area.id,
          };
          total.map((item2) => {
            var linha = {
              quantidadeAtendimentos: item2.quantidade_pagamentos,
              usuario: item2.usuario,
              data: formatarData.ptBrComHorario(item2.criado_em),
              dinheiro: "0,00",
              credito: "0,00",
              debito: "0,00",
              pix: "0,00",
              soma: null,
            };
            item2.total_pagamentos.map((item) => {
              linha.soma += parseFloat(item.valor);
              if (item.id === 1) {
                linha.dinheiro = parseFloat(item.valor).toLocaleString(
                  "pt-br",
                  { minimumFractionDigits: 2 }
                );
              } else if (item.id === 2) {
                linha.credito = parseFloat(item.valor).toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                });
              } else if (item.id === 3) {
                linha.debito = parseFloat(item.valor).toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                });
              } else if (item.id === 4) {
                linha.pix = parseFloat(item.valor).toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                });
              }
            });
            linha.soma = linha.soma.toLocaleString("pt-br", {
              minimumFractionDigits: 2,
            });

            totalFiltrado.linhas.push(linha);
          });

          relatoriosData.push(totalFiltrado);
        });

        this.relatorioArea = relatoriosData;
        console.log(relatoriosData, "this.relatorioArea");
      } catch (e) {
        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
      } finally {
        this.$store.commit(`layout/SET_LOADING`, false);
      }
    },
  },
};
</script>

<style scoped>
.titulo-area {
  font-weight: bold;
}
.col {
  flex-basis: 200px;
}
.calendario-input {
  height: 40px;
  border-radius: 5px;
  border: 2px solid black;
}
@media (max-width: 576px) {
  .datas-input {
    display: block !important;
  }
  .datas-container {
    text-align: center;
  }
  .calendario-input {
    margin: 5px 0;
  }
  .icone {
    display: none;
  }
}
</style>
